import {ContentBox} from '@hconnect/common/components/ContentBox'
import {DashboardLink} from '@hconnect/common/components/downtime'
import {useExpandedGroups} from '@hconnect/common/hooks/useExpandGroups'
import {ManualKpiToUpdate, ManualKpiResolution} from '@hconnect/common/types'
import {getTranslationKey} from '@hconnect/common/utils/translation.utils'
import {useNotification} from '@hconnect/uikit'
import {ExpandCollapseButton} from '@hconnect/uikit/src/lib2'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import {Box, Button, Typography} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {PerformanceManualKpiContainer} from '../../containers/shiftSummary/PerformanceManualKpiContainer'
import {useProcessStagesForManualKpis, useUpdateKpis} from '../../hooks/api/kpis'
import {useConfig} from '../../hooks/useConfig'
import {useFilterDatePicker} from '../../hooks/useDatePicker'
import {useHasPermission} from '../../hooks/usePermission'
import {KpiFormData} from '../../types/kpi.types'
import {mapFormDataToKpisToUpdate} from '../../utils'

export const ManualKpis: React.FC = () => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const hasPermission = useHasPermission()
  const {notify} = useNotification()
  const {timeRange, isSingleShift, quickSelectSlot} = useFilterDatePicker()
  const {plantId} = useConfig()
  const [isEditMode, setIsEditMode] = useState(false)

  const isManualKpiEditAllowed = hasPermission('CREATE_SHIFT_REPORT')

  const isTimeFrameSupported = isSingleShift || quickSelectSlot === 'yesterday'
  const {data: processStages = [], isInitialLoading: isProcessStagesLoading} =
    useProcessStagesForManualKpis(language, {enabled: isTimeFrameSupported})

  const [openProcessStages, setOpenProcessStages] = useExpandedGroups(
    processStages.map(({code}) => code),
    'expanded-manual-kpis'
  )

  const {control, handleSubmit} = useForm<KpiFormData>({shouldUnregister: true})

  const [manualKpiList, setManualKpiList] = useState<ManualKpiToUpdate[]>([])

  const {
    mutate: updateKpis,
    error: updateError,
    reset: updateReset
  } = useUpdateKpis(
    isSingleShift ? ManualKpiResolution.PerShift : ManualKpiResolution.Daily,
    timeRange.endDate,
    {
      onSuccess: () => {
        notify('success', t('performance.action.kpiSaveSuccess'))
        setIsEditMode(false)
      }
    }
  )

  const onSubmit = useCallback(
    (data: KpiFormData) => {
      const updatedKpis = mapFormDataToKpisToUpdate(data, manualKpiList)
      updateKpis(updatedKpis)
    },
    [manualKpiList, updateKpis]
  )

  return (
    <ContentBox
      title={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={1}
        >
          {t('performance.label.performance')}
          {isTimeFrameSupported && (
            <Box display="flex" alignItems="center" flexWrap="wrap">
              {isEditMode ? (
                <>
                  <Button
                    onClick={() => setIsEditMode(false)}
                    startIcon={<CloseOutlinedIcon />}
                    variant="text"
                    data-test-id="manual-kpis-close-button"
                  >
                    {t('action.cancel')}
                  </Button>
                  <Button
                    onClick={() => void handleSubmit(onSubmit)()}
                    startIcon={<DoneOutlinedIcon />}
                    variant="contained"
                    data-test-id="manual-kpis-save-button"
                  >
                    {t('action.save')}
                  </Button>
                </>
              ) : (
                <>
                  <ExpandCollapseButton
                    variant="text"
                    expanded={openProcessStages.length === processStages.length}
                    onClick={() => setOpenProcessStages('all')}
                    data-test-id="performance-expand-all-button"
                    expandLabel={t('action.expand')}
                    collapseLabel={t('action.collapse')}
                  />
                  {isManualKpiEditAllowed && (
                    <Button
                      onClick={() => setIsEditMode(true)}
                      startIcon={<ModeEditOutlineOutlinedIcon />}
                      variant="text"
                      data-test-id="manual-kpis-edit-button"
                    >
                      {t('summary.action.edit')}
                    </Button>
                  )}
                </>
              )}
              {!isEditMode && <DashboardLink plantId={plantId} title={t('goToDashboard')} />}
            </Box>
          )}
        </Box>
      }
      mode="contentDependentHeight"
      bodyWithPadding
      isLoading={isProcessStagesLoading}
      minHeight={isTimeFrameSupported ? '450px' : undefined}
      data-test-id="manual-kpis"
    >
      {isTimeFrameSupported ? (
        <>
          <PerformanceManualKpiContainer
            isEditMode={isEditMode}
            isEnabled={isTimeFrameSupported}
            isProcessStagesLoading={isProcessStagesLoading}
            openProcessStages={openProcessStages}
            setOpenProcessStages={setOpenProcessStages}
            processStages={processStages}
            manualKpiList={manualKpiList}
            setManualKpiList={setManualKpiList}
            control={control}
            updateError={updateError}
            updateReset={updateReset}
          />
        </>
      ) : (
        <Typography data-test-id="manual-kpis-unavailable-label">
          {t(getTranslationKey('performance.label.unavailable'))}
        </Typography>
      )}
    </ContentBox>
  )
}
