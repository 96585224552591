import {useMutation, useQueryClient, UseMutationOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {CreateInforWorkOrderRequest} from '../../../types/maintenanceNotification.types'
import {useConfig} from '../../useConfig'
import {useApi} from '../useApi'
import {QueryKey} from '../useEvents'

export const useInforWorkOrderRequest = (
  options?: UseMutationOptions<string, AxiosError, CreateInforWorkOrderRequest>
) => {
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()
  const queryClient = useQueryClient()

  return useMutation<string, AxiosError, CreateInforWorkOrderRequest>(
    async (payload) => {
      const path = `/shifthandover/${plantId}/infor-work-order-request`

      const mutation = await axiosInstance.post<string>(path, payload)
      return mutation.data
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([QueryKey, variables.eventId])
        await options?.onSuccess?.(data, variables, context)
      }
    }
  )
}
