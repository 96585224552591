import {EquipmentData} from '@hconnect/common/types'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useTranslation} from 'react-i18next'

import {MainEquipmentsDto} from '../../types/shiftHandover.types'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'

const QueryKey = 'mainEquipments'

export const useMainEquipments = (options?: UseQueryOptions<EquipmentData[], AxiosError>) => {
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()
  const {
    i18n: {language}
  } = useTranslation()

  return useQuery<EquipmentData[], AxiosError>(
    [QueryKey, language, plantId],
    async () => {
      const path = `/shifthandover/${plantId}/config/main-equipments`
      const config = {
        params: {
          language
        }
      }

      const response = await axiosInstance.get<MainEquipmentsDto>(path, config)
      return response.data
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  )
}
