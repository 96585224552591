import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useConfig} from '../useConfig'

import {useApi} from './useApi'

export const useTaskScheduleDelete = (
  options?: UseMutationOptions<unknown, AxiosError, string>
) => {
  const {axiosInstance} = useApi()
  const queryClient = useQueryClient()
  const {plantId} = useConfig()
  return useMutation<unknown, AxiosError, string>(
    async (taskId: string) => {
      const path = `/shifthandover/${plantId}/tasks/schedules?taskId=${taskId}`
      const response = await axiosInstance.delete(path)
      return response.data
    },
    {
      // allow the caller to override the default options
      ...options,

      // make sure to invalidate the Queries after the regular onSuccess process is done
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries()
        await options?.onSuccess?.(data, variables, context)
      }
    }
  )
}
