import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useConfig} from '../../useConfig'
import {useApi} from '../useApi'

import {QueryKey as ManualKpisQueryKey} from './useManualKpis'
import {QueryKey as ProcessStageQueryKey} from './useProcessStagesForManualKpis'

export const useProcessStagesForManualKpisDelete = (
  options?: UseMutationOptions<unknown, AxiosError, string>
) => {
  const {axiosInstance} = useApi()
  const queryClient = useQueryClient()
  const {plantId} = useConfig()

  return useMutation<unknown, AxiosError, string>(
    async (processStage: string) => {
      const path = `/shifthandover/${plantId}/process-stages/${processStage}`
      const response = await axiosInstance.delete(path)
      return response.data
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([ProcessStageQueryKey])
        await queryClient.invalidateQueries([ManualKpisQueryKey])
        await options?.onSuccess?.(data, variables, context)
      }
    }
  )
}
