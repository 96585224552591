import {DateTimeFormat} from '@hconnect/common/components/dateTime/DateTimeFormat'
import {DateTime, WorkOrderTarget} from '@hconnect/common/types'
import {Typography} from '@mui/material'
import moment, {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {hasMaintenanceNotificationCreationTimedOut} from '../../common/utils/eventDetailsHelper'
import {useSapWorkOrders} from '../../hooks/api/useSapWorkOrders'
import {useConfig} from '../../hooks/useConfig'
import {ShiftEvent} from '../../types/shiftHandover.types'

type InfoProps = {
  label?: string
  date?: DateTime | undefined
  userName?: string
  'data-test-id'?: string
}

type EventInfoProps = {
  item: Partial<ShiftEvent>
  sapNotificationFailed?: boolean
}

export const Info: React.FC<InfoProps> = ({label, userName, date, 'data-test-id': dataTestId}) => {
  const {t} = useTranslation()
  const {timezone} = useConfig()

  return (
    <Typography component={'p'} gutterBottom variant="caption" data-test-id={dataTestId}>
      {label && `${label} `}
      {userName && date && (
        <>
          {userName} {t('eventsTable.happenOn')} <DateTimeFormat date={date} timezone={timezone} />
        </>
      )}
    </Typography>
  )
}

export const WorkOrders: React.FC<{notificationId: string}> = ({notificationId}) => {
  const {t} = useTranslation()
  const {data, error} = useSapWorkOrders(notificationId)

  return (
    <>
      {error &&
        (error?.response?.status === 404 ? (
          <Typography
            component={'p'}
            gutterBottom
            variant={'caption'}
            data-test-id="work-orders-no-wo-created"
          >
            {t('shiftEvent.label.noWorkOrderCreated')}
          </Typography>
        ) : (
          <Typography
            component={'p'}
            gutterBottom
            variant={'caption'}
            color={'error'}
            data-test-id="work-orders-error"
          >
            {t('shiftEvent.label.workOrderError', {errorMessage: error.message})}
          </Typography>
        ))}
      {data && (
        <Typography
          component={'p'}
          gutterBottom
          variant={'caption'}
          key={data.id}
          data-test-id={`work-order-${data.id}`}
        >
          {t('shiftEvent.label.workOrder', {workOrderId: data.id})}
        </Typography>
      )}
    </>
  )
}

const SapMaintenanceNotificationDetails = ({
  item,
  notificationFailed
}: {
  item: Partial<ShiftEvent>
  notificationFailed: boolean | undefined
}) => {
  const {timezone} = useConfig()
  const {t} = useTranslation()
  const sapMaintenanceNotificationId = item.sapMaintenanceNotificationId

  return (
    <>
      {(hasMaintenanceNotificationCreationTimedOut(item, moment.tz(timezone)) ||
        notificationFailed) && (
        <Typography
          variant="caption"
          color="error"
          data-test-id="event-info-sap-notification-error"
        >
          {t('shiftEvent.error.maintenanceNotificationCreationTimeout')}
        </Typography>
      )}
      {sapMaintenanceNotificationId && (
        <Info
          label={`${t('shiftEvent.label.sapPmNotificationId')}: ${sapMaintenanceNotificationId}`}
          data-test-id="event-info-sap-notification-label"
        />
      )}
      {sapMaintenanceNotificationId && <WorkOrders notificationId={sapMaintenanceNotificationId} />}
    </>
  )
}

const InforMaintenanceNotificationDetails = ({
  item,
  notificationFailed
}: {
  item: Partial<ShiftEvent>
  notificationFailed: boolean | undefined
}) => {
  const {t} = useTranslation()
  const inforWorkOrderRequestId = item.inforWorkOrder?.requestId

  return (
    <>
      {notificationFailed && (
        <Typography
          variant="caption"
          color="error"
          data-test-id="event-info-infor-notification-error"
        >
          {t('shiftEvent.error.maintenanceNotificationCreationTimeout')}
        </Typography>
      )}
      {!!inforWorkOrderRequestId && (
        <Typography variant="caption" data-test-id="event-info-infor-notification-label">
          {t('shiftEvent.label.inforWorkOrderRequestId')}: {inforWorkOrderRequestId}
        </Typography>
      )}
    </>
  )
}

type EventCreatorProps = {
  name?: string
  created?: Moment
  lastModified?: Moment
  modifiedBy?: string
}
export const EventCreator: React.FC<EventCreatorProps> = ({
  name,
  created,
  lastModified,
  modifiedBy
}) => {
  const {t} = useTranslation()
  return (
    <>
      <Info label={t('shiftEvent.label.createdBy')} userName={name ?? ''} date={created} />
      {lastModified && !lastModified.isSame(created) && (
        <Info
          label={t('shiftEvent.label.modifiedBy')}
          userName={modifiedBy ?? ''}
          date={lastModified}
        />
      )}
    </>
  )
}

export const EventInfo: React.FC<EventInfoProps> = ({item, sapNotificationFailed}) => {
  const {workOrderTarget} = useConfig()
  const isInforWorkOrder = workOrderTarget === WorkOrderTarget.Infor
  const isSapWorkOrder = workOrderTarget === WorkOrderTarget.Sap

  return (
    <>
      <EventCreator
        name={item.createdBy?.name}
        created={item.created}
        modifiedBy={item.modifiedBy}
        lastModified={item.lastModified}
      />
      {isSapWorkOrder && (
        <SapMaintenanceNotificationDetails item={item} notificationFailed={sapNotificationFailed} />
      )}
      {isInforWorkOrder && (
        <InforMaintenanceNotificationDetails
          item={item}
          notificationFailed={sapNotificationFailed}
        />
      )}
    </>
  )
}
