import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {ShiftEvent, EventUpdate} from '../../types/shiftHandover.types'
import {useConfig} from '../useConfig'

import {updateEvent} from './updateEvent'
import {useApi} from './useApi'

type Variables = {
  event: EventUpdate
  scheduleRecurringTask?: boolean
}

export const useEventUpdate = (options?: UseMutationOptions<ShiftEvent, AxiosError, Variables>) => {
  const {axiosInstance} = useApi()
  const queryClient = useQueryClient()
  const {plantId, timezone} = useConfig()

  return useMutation<ShiftEvent, AxiosError, Variables, unknown>(
    (variables) =>
      updateEvent(
        axiosInstance,
        plantId,
        variables.event,
        timezone,
        variables.scheduleRecurringTask
      ),
    {
      // allow the caller to override the default options
      ...options,

      // make sure to invalidate the Queries after the regular onSuccess process is done
      onSuccess: (data, variables, context) => {
        // promise results are not required, hence we can just trigger the promises
        // This is a quick-fix for HCP-23572
        void options?.onSuccess?.(data, variables, context)
        void queryClient.invalidateQueries()
      }
    }
  )
}
