import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {INSTRUCTIONS_TENANT_ID} from '../../consts'
import {mapInstructionDto} from '../../mappers'
import {
  InstructionsDto,
  DocumentSearchCriteria,
  Instruction,
  InstructionDto
} from '../../types/documents.types'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'

const QueryKey = 'instructions-search'

export const useFilteredInstructions = (
  settings: DocumentSearchCriteria,
  options?: UseQueryOptions<InstructionDto[], AxiosError, Instruction[]>
) => {
  const {axiosInstance} = useApi()
  const {plantId, timezone} = useConfig()

  return useQuery<InstructionDto[], AxiosError, Instruction[]>(
    [QueryKey, settings, plantId, timezone],
    async () => {
      const response = await axiosInstance.get<InstructionsDto>(
        `/instructions/${INSTRUCTIONS_TENANT_ID}/plants/${plantId}/filtered-instructions`,
        {
          params: settings
        }
      )
      return response.data
    },
    {
      ...options,
      select: (data) => data.map((instruction) => mapInstructionDto(instruction, timezone))
    }
  )
}
