import {useQuery} from '@tanstack/react-query'

import {useConfig} from '../useConfig'

import {useApi} from './useApi'

const QueryKey = 'planner-groups'

export const usePlannerGroups = () => {
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()

  return useQuery([QueryKey, plantId], async () => {
    const {data} = await axiosInstance.get<string[]>(`/plantconfig/${plantId}/planner-groups`)
    return data
  })
}
