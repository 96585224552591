import {useQueryClient} from '@tanstack/react-query'
import {useEffect} from 'react'

export const useCancelInactiveQueries = () => {
  const queryClient = useQueryClient()

  useEffect(() => {
    return () => {
      queryClient
        .getQueryCache()
        .getAll()
        .forEach((query) => {
          /**
           * 1. We cancel inactive queries when the user navigates to a new page.
           * 2. We check all the queries if there are observers attached and if not we can cancel the call.
           *    A query becomes inactive when it has no observers, which basically means:
           *    all components that use it via useQuery have unmounted.
           */
          if (query.queryKey && query.getObserversCount() === 0) {
            queryClient.cancelQueries(query.queryKey).catch((error) => {
              console.error('Error canceling query:', error)
            })
          }
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href])
}
