import {LoadingSwitcher, useNotification} from '@hconnect/uikit'
import React, {useCallback, FC} from 'react'
import {useTranslation} from 'react-i18next'

import {useDocumentCreate} from '../../hooks/api/useDocumentCreate'
import {DocumentUpdate} from '../../types/documents.types'

import {DocumentFormContainer} from './DocumentFormContainer'

type DocumentCreateProps = {
  doClose: () => void
  onSuccessSubmit: (documentId: string) => void
}

export const DocumentCreate: FC<DocumentCreateProps> = ({onSuccessSubmit, doClose}) => {
  const {t} = useTranslation()
  const {notify} = useNotification()

  const createAction = useDocumentCreate({
    onSuccess: ({id}) => onSuccessSubmit(id),
    onError: () => {
      notify('error', t('error.failedToUploadFile'))
    }
  })

  const doSubmit = useCallback(
    (item: DocumentUpdate) => {
      createAction.mutate(item)
    },
    [createAction]
  )

  return (
    <LoadingSwitcher isLoading={createAction.isLoading}>
      <DocumentFormContainer doClose={doClose} doSubmit={doSubmit} />
    </LoadingSwitcher>
  )
}
