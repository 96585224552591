import {SideCardModes} from '@hconnect/common/components/eventsList/types'
import {Add} from '@mui/icons-material'
import {Box, Button} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {FilterOptions} from '../../common/utils/filterParameter'
import {useEventsSearch} from '../../hooks/api/useEventsSearch'
import {useConfig} from '../../hooks/useConfig'
import {useDraggableTaskContext} from '../../hooks/useDraggableTask'
import {useOnPageWithoutUnmountFocus} from '../../hooks/useOnPageWithoutUnmountFocus'
import {usePreviousValue} from '../../hooks/usePreviousValue'
import {TasksList} from '../../layouts/TasksList'
import {ShiftEvent, SortBy, Status} from '../../types/shiftHandover.types'
import {SideCard, getItemId} from '../common/SideCard'

interface TaskBoxContainerProps {
  filterOptions: FilterOptions
}

const REFETCH_INTERVAL_PERIOD_IN_MS = 180000
const COMMON_FETCH_PARAMS = [
  SortBy.Details,
  undefined,
  0,
  50,
  {
    refetchInterval: REFETCH_INTERVAL_PERIOD_IN_MS,
    refetchOnWindowFocus: false
  }
] as const

const TasksBox: React.FC<{
  'data-test-id': string
  title: string
  tasks?: ShiftEvent[]
  isLoading?: boolean
  withCreateButton?: boolean
  markDueDateBefore?: Moment
  section: Status
}> = (props) => {
  const {tasks: taskList, section, title, isLoading, markDueDateBefore, withCreateButton} = props
  const tasks = useMemo(() => taskList && [...taskList].reverse(), [taskList])
  const [sideCardMode, setSideCardMode] = useState<SideCardModes<ShiftEvent> | null>(null)
  const previousActiveId = usePreviousValue(sideCardMode && getItemId(sideCardMode)) || undefined
  const {draggedFrom, onDragStart, onDragOver, onDragEnd, draggingOver} = useDraggableTaskContext()

  React.useEffect(() => {
    setSideCardMode(null)
  }, [draggedFrom])

  const {t} = useTranslation()

  return (
    <Box
      data-test-id={props['data-test-id']}
      height={'100%'}
      onDragOver={(event) => {
        event.stopPropagation()
        event.preventDefault()
        onDragOver(section, () => {
          setSideCardMode(null)
        })
      }}
      onDrop={() => {
        onDragEnd(section)
      }}
    >
      {sideCardMode ? (
        <SideCard
          {...sideCardMode}
          setMode={(options: SideCardModes<ShiftEvent>) => {
            if (options.mode === 'detailsMode') {
              // show the overview instead of the details
              setSideCardMode(null)
            } else {
              setSideCardMode(options)
            }
          }}
          doClose={() => {
            setSideCardMode(null)
          }}
          data-test-id={'page-tasks'}
        />
      ) : (
        <TasksList
          tasks={tasks}
          title={title}
          options={
            withCreateButton && (
              <Button
                variant={'text'}
                color="primary"
                data-test-id="event-create"
                onClick={() => {
                  setSideCardMode({mode: 'createMode', preSetEventType: 'task'})
                }}
                startIcon={<Add />}
              >
                {t('shiftEvent.action.createTask')}
              </Button>
            )
          }
          isLoading={isLoading}
          markDueDateBefore={markDueDateBefore}
          scrollToID={previousActiveId}
          onTaskClick={(task: ShiftEvent) => {
            setSideCardMode({mode: 'detailsMode', itemId: task.id})
          }}
          onDragStart={(task) => {
            onDragStart(section, task)
          }}
          isDragOver={draggingOver === section}
        />
      )}
    </Box>
  )
}

export const PendingTasksBox: React.FC<TaskBoxContainerProps> = ({filterOptions}) => {
  const {t} = useTranslation()
  const config = useConfig()

  const options: FilterOptions = {
    ...filterOptions,
    eventType: ['task'],
    status: ['pending']
  }
  const {data, isInitialLoading, isFetching, refetch} = useEventsSearch(
    options,
    ...COMMON_FETCH_PARAMS
  )
  useOnPageWithoutUnmountFocus(refetch)

  return (
    <TasksBox
      data-test-id="task-box-pending"
      isLoading={isFetching || isInitialLoading}
      tasks={data?.events}
      title={`${t('shiftEvent.status.pending')}${data ? ` (${data?.events.length})` : ''}`}
      markDueDateBefore={config.plantNow().clone().startOf('day')}
      section={'pending'}
      withCreateButton
    />
  )
}

export const OngoingTasksBox: React.FC<TaskBoxContainerProps> = ({filterOptions}) => {
  const {t} = useTranslation()
  const config = useConfig()

  const options: FilterOptions = {
    ...filterOptions,
    eventType: ['task'],
    status: ['ongoing']
  }
  const {data, isInitialLoading, isFetching, refetch} = useEventsSearch(
    options,
    ...COMMON_FETCH_PARAMS
  )
  useOnPageWithoutUnmountFocus(refetch)

  return (
    <TasksBox
      data-test-id="task-box-ongoing"
      isLoading={isFetching || isInitialLoading}
      tasks={data?.events}
      title={`${t('shiftEvent.status.ongoing')}${data ? ` (${data?.events.length})` : ''}`}
      markDueDateBefore={config.plantNow().clone().startOf('day')}
      section={'ongoing'}
    />
  )
}

export const DoneTasksBox: React.FC<TaskBoxContainerProps> = ({filterOptions}) => {
  const {t} = useTranslation()

  const options: FilterOptions = {
    ...filterOptions,
    eventType: ['task'],
    status: ['done']
  }

  const {
    data: tasks,
    isFetching,
    isLoading,
    refetch
  } = useEventsSearch(options, ...COMMON_FETCH_PARAMS)
  useOnPageWithoutUnmountFocus(refetch)

  return (
    <TasksBox
      data-test-id="task-box-done"
      isLoading={isFetching || isLoading}
      tasks={tasks?.events}
      title={`${t('shiftEvent.status.done')}${tasks ? ` (${tasks?.events.length})` : ''}`}
      section={'done'}
    />
  )
}
