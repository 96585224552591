import {ShiftInput} from '@hconnect/common/types'
import {useMutation, useQueryClient, UseMutationOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {map} from 'lodash'

import {formatTimeHHMM} from '../../common/utils/datetime'

import {useApi} from './useApi'

export const QueryKey = 'events-load-list'

export const useShiftsCreate = (
  plantId: string,
  options?: UseMutationOptions<ShiftInput[], AxiosError, ShiftInput[]>
) => {
  const {axiosInstance} = useApi()
  const queryClient = useQueryClient()
  const path = `/shifthandover/${plantId}/shifts`

  return useMutation(
    async (data: ShiftInput[]) => {
      const response = await axiosInstance.post(
        path,
        map(data, (s) => ({...s, start: formatTimeHHMM(s.start), end: formatTimeHHMM(s.end)}))
      )
      return response.data
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries()
        await options?.onSuccess?.(data, variables, context)
      }
    }
  )
}
