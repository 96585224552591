import CommentIcon from '@mui/icons-material/Textsms'
import {Box, Button, TextField} from '@mui/material'
import {UseMutationResult} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {EditCommentParameter} from '../../hooks/api/useCommentsAction'
import {CreateCommentParameter} from '../../hooks/api/useShiftComments'
import {Comment, Iso8601} from '../../types/shiftHandover.types'

const CommentForm: React.FC<{
  onSave: (desc: string | undefined) => void
  onCancel: () => void
  commentDesc?: string
}> = ({onSave, onCancel, commentDesc}) => {
  const {t} = useTranslation()
  const [newCommentText, setNewCommentText] = useState<string | undefined>(commentDesc)

  return (
    <Box my={2}>
      <TextField
        sx={{mb: '16px!important'}}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        multiline
        data-test-id="comment-input"
        inputProps={{'data-test-id': 'comment-input-field'}}
        fullWidth
        label={t('shiftEvent.label.comment')}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setNewCommentText(event.target.value)
        }
        value={newCommentText}
      />
      <Button
        color="primary"
        variant="contained"
        disabled={newCommentText?.trim().length === 0}
        onClick={() => onSave(newCommentText)}
        data-test-id="submit-comment-button"
      >
        {t('action.save')}
      </Button>
      <Button variant="text" onClick={onCancel} data-test-id="cancel-comment-button" sx={{ml: 1}}>
        {t('action.cancel')}
      </Button>
    </Box>
  )
}

export const NewComment: React.FC<{
  submittedForTimestamp?: Iso8601
  createCommentAction: UseMutationResult<Comment, AxiosError, CreateCommentParameter, unknown>
  eventId?: string
}> = ({createCommentAction, eventId, submittedForTimestamp}) => {
  const {t} = useTranslation()
  const [showForm, setShowForm] = useState<boolean>(false)

  const doCreate = React.useCallback(
    (newCommentText) => {
      if (!newCommentText || newCommentText.trim().length === 0) return

      createCommentAction.mutate({
        eventId,
        submittedForTimestamp,
        description: newCommentText.trim()
      })
      setShowForm(false)
    },
    [eventId, createCommentAction, submittedForTimestamp]
  )

  if (!showForm) {
    return (
      <Button
        data-test-id="start-comment-button"
        onClick={() => setShowForm(true)}
        variant="text"
        startIcon={<CommentIcon />}
      >
        {t('shiftEvent.action.addComment')}
      </Button>
    )
  }

  return <CommentForm onSave={doCreate} onCancel={() => setShowForm(false)} />
}

export const EditComment: React.FC<{
  comment: Comment
  onCancel: () => void
  editCommentAction: UseMutationResult<Comment, AxiosError, EditCommentParameter>
}> = ({comment, editCommentAction, onCancel}) => {
  const doEdit = React.useCallback(
    (newCommentText) => {
      if (
        !newCommentText ||
        newCommentText.trim().length === 0 ||
        newCommentText === comment.description
      )
        return

      editCommentAction.mutate({
        commentId: comment.id,
        description: newCommentText.trim()
      })
      onCancel()
    },
    [comment.description, comment.id, editCommentAction, onCancel]
  )

  return <CommentForm commentDesc={comment.description} onSave={doEdit} onCancel={onCancel} />
}
