import {mapEquipmentExpertSystemUsages} from '@hconnect/common/mappers'
import {
  Iso8601,
  EquipmentExpertSystemUsage,
  EquipmentExpertSystemUsageDto,
  UsageDto
} from '@hconnect/common/types'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useConfig} from '../useConfig'

import {useApi} from './useApi'
const QueryKey = 'expert-system-eqs'

export const useExpertSystemData = (
  timeRange: {
    startDate: Iso8601
    endDate: Iso8601
  },
  options?: UseQueryOptions<EquipmentExpertSystemUsage[], AxiosError>
) => {
  const {plantId, timezone} = useConfig()
  const {axiosInstance} = useApi()

  return useQuery<EquipmentExpertSystemUsage[], AxiosError>(
    [QueryKey, plantId, timeRange, timezone],
    async () => {
      const path = `/shifthandover/${plantId}/expert-system/usages`
      const params = {params: {...timeRange}}

      const response = await axiosInstance.get<UsageDto>(path, params)

      return mapEquipmentExpertSystemUsages(
        (response.data[plantId] as EquipmentExpertSystemUsageDto[]) || [],
        timezone
      )
    },
    {
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 1,
      ...options
    }
  )
}
