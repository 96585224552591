import {FilterTimeRange} from '@hconnect/common/types'
import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {isStoppageEventType} from '../../common/utils/eventType'
import {FilterOptions} from '../../common/utils/filterParameter'
import {EventSearchCriteria, SortBy, SortOrder} from '../../types/shiftHandover.types'
import {
  stoppageCodeSearchParamsToFilterParams,
  maintenanceNotificationSearchParamsToFilterParams
} from '../../utils/stoppageHelper'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'
import {generateTimeRange} from './useEventsSearch'

export const QueryKey = 'events-search-download'

export const useEventsSearchDownload = (
  input: FilterOptions,
  sortBy: SortBy = SortBy.Details,
  sortOrder?: SortOrder,
  pageNumber = 0,
  itemsPerPage = 50
) => {
  const {axiosInstance} = useApi()
  const config = useConfig()
  const {plantNow, shifts, plantId} = config

  const timeRange: FilterTimeRange = generateTimeRange(input.timeRange, shifts, plantNow)

  return useQuery<Blob, AxiosError>(
    [QueryKey, input, timeRange, pageNumber, itemsPerPage, sortBy, sortOrder, plantId],
    async ({signal}) => {
      const isStoppage = input.eventType?.some(isStoppageEventType)

      const settings: EventSearchCriteria = {
        timeRange,
        processStage: input.processStage,
        equipment: input.equipment,
        mainEquipment: input.mainEquipment,
        eventType: input.eventType,
        priority: input.priority,
        status: input.status,
        category: input.category,
        pageNumber,
        itemsPerPage,
        sortBy,
        sortOrder,
        stoppageCodesFilter: stoppageCodeSearchParamsToFilterParams(input.stoppageCode),
        maintenanceNotificationExists: maintenanceNotificationSearchParamsToFilterParams(
          input.maintenanceNotificationExists
        )
      }

      const response = await axiosInstance.post<Blob>(
        `/shifthandover/${plantId}/events/excel-export?includeStoppagesFromPxTrend=${!!isStoppage}`,
        settings,
        {responseType: 'blob', signal}
      )

      return response.data
    },
    {enabled: false, refetchOnWindowFocus: false}
  )
}
