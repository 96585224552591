import {EquipmentCategory} from '@hconnect/common/types'
import {Grid} from '@mui/material'
import {isEmpty, sortBy} from 'lodash'
import {FC} from 'react'

import {PerformanceSummary} from '../../components/kpiPerformance'
import {ManualKpis} from '../../components/manualKpis/ManualKpis'
import {useEquipmentPerformances} from '../../hooks/api/useEquipmentPerformances'
import {useConfig} from '../../hooks/useConfig'
import {useFilterDatePicker} from '../../hooks/useDatePicker'

export const PerformanceDataView: FC = () => {
  const {cceAvailable} = useConfig()
  const {timeRange} = useFilterDatePicker()

  const {data: equipmentPerformances, isInitialLoading: isEquipmentPerformancesLoading} =
    useEquipmentPerformances(timeRange, {
      enabled: cceAvailable,
      select: (data) => sortBy(data, 'equipment.id')
    })

  if (cceAvailable) {
    const firstDataSet = equipmentPerformances?.filter(
      (equipmentPerformance) =>
        equipmentPerformance.equipment.equipmentCategory &&
        [EquipmentCategory.RAW, EquipmentCategory.CRUSHER, EquipmentCategory.COAL].includes(
          equipmentPerformance.equipment.equipmentCategory
        )
    )
    const secondDataSet = equipmentPerformances?.filter(
      (equipmentPerformance) =>
        !equipmentPerformance.equipment.equipmentCategory ||
        [EquipmentCategory.CEMENT, EquipmentCategory.CLINKER, EquipmentCategory.UNKNOWN].includes(
          equipmentPerformance.equipment.equipmentCategory
        )
    )

    return (
      <Grid container spacing={2} width="100%">
        <>
          {!isEmpty(firstDataSet) && (
            <Grid item xs={12} md={6}>
              <PerformanceSummary
                equipmentPerformances={firstDataSet}
                isLoading={isEquipmentPerformancesLoading}
                data-test-id="equipment-performance-summary-0"
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <PerformanceSummary
              equipmentPerformances={secondDataSet}
              isLoading={isEquipmentPerformancesLoading}
              data-test-id="equipment-performance-summary-1"
            />
          </Grid>
        </>
      </Grid>
    )
  }

  return (
    <Grid container spacing={2} width="100%">
      <Grid item xs={12} md={6}>
        <ManualKpis />
      </Grid>
    </Grid>
  )
}
