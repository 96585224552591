import {getUserProfile} from '@hconnect/apiclient'
import {Env, whiteListByPermission} from '@hconnect/common/hproduce'
import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {BrowserRouter as Router} from 'react-router-dom'

import {App} from './App'
import {getUserPermissions} from './common/services'
import {isUnauthenticatedError} from './common/utils/errorhelper'
import {HLog} from './common/utils/logging'
import {AccessType, getPlantId} from './common/utils/plantIdHelper'
import {trackAnalyticsEvent} from './common/utils/trackAnalyticsEvents'
import {PageLoadingSpinner} from './components/common/PageLoadingSpinner'
import {Api} from './hooks/api/types'
import {PlantSelect} from './pages/PlantSelect'
import {SimpleErrorPage} from './pages/SimpleErrorPage'

const env = process.env.REACT_APP_STAGE as Env

const initialize = async (api: Api) => {
  const {loginFlow, axiosInstance} = api
  const loginResponse = await loginFlow.getLoginState()
  if (!loginResponse.loggedIn) {
    await loginFlow.startLoginProcess()
    // user not logged in, application not ready
    return
  }

  const {user_id: userId} = loginResponse.decodedToken

  const user = await getUserProfile(axiosInstance)(userId).catch((err: Error | AxiosError) => {
    if (isUnauthenticatedError(err)) {
      void loginFlow.startLoginProcess()
    }
  })

  if (!user) {
    // user could not be loaded, application not ready
    return
  }

  const permissions = await getUserPermissions(axiosInstance)(userId)

  const whiteList = whiteListByPermission(env, permissions)

  // successfully initialized
  return {
    loginResponse,
    user,
    permissions,
    whiteList
  }
}

export const AppWrapper: React.FC<{api: Api}> = ({api}) => {
  const {t} = useTranslation()
  const {data, error} = useQuery(['initialize'], async () => initialize(api))

  if (error) {
    const errorMessage =
      error instanceof Error ? error.message : typeof error === 'string' ? error : 'Unknown error'

    HLog('cockpit').error(errorMessage)
    trackAnalyticsEvent('onErrorPageTracking', {errorMessage})
    return <SimpleErrorPage message={errorMessage} withRedirectButton={true} env={env} />
  }

  if (!data) {
    return <PageLoadingSpinner />
  }

  const {loginFlow} = api
  const {loginResponse, user, permissions, whiteList} = data
  if (!whiteList.optionsPerApp['Cockpit']) {
    trackAnalyticsEvent('onErrorPageTracking', {
      userId: user.id,
      errorMessage: 'Your account is missing the permission to view this report'
    })
    return (
      <SimpleErrorPage
        message={t('error.missingPermission')}
        logout={() => void loginFlow.startLogoutProcess()}
      />
    )
  }

  const {accessType, plantId} = getPlantId(window.location.pathname.split('/')[1], whiteList)

  if (accessType === AccessType.MultiplePlants) {
    return (
      <Router>
        <PlantSelect user={user} loginFlow={api.loginFlow} whiteList={whiteList} />
      </Router>
    )
  }

  return (
    <App
      loginResponse={loginResponse}
      api={api}
      user={user}
      permissions={permissions}
      whiteList={whiteList}
      plantId={plantId}
    />
  )
}
