import {UseMutationResult} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import React, {useMemo} from 'react'

import {categoryToInforDepartmentCodeMapper} from '../../mappers/inforDepartmentCode.mappers'
import {shiftEventToInforPriorityMapper} from '../../mappers/inforPriority.mappers'
import {CreateInforWorkOrderRequest} from '../../types/maintenanceNotification.types'
import {ShiftEvent} from '../../types/shiftHandover.types'

import {CreateInforMaintenanceNotificationFormDialog} from './CreateInforMaintenanceNotificationFormDialog'

type Props = {
  event: ShiftEvent
  showDialog: boolean
  setDialog: (state: boolean) => void
  onNotificationCreated(): void
  onError?(): void
  createNotification: UseMutationResult<string, AxiosError, CreateInforWorkOrderRequest>
}

export const CreateInforMaintenanceNotification: React.FC<Props> = ({
  event,
  showDialog,
  setDialog,
  onError,
  onNotificationCreated,
  createNotification: createNotificationProp
}) => {
  const createNotification = (notification: CreateInforWorkOrderRequest) => {
    createNotificationProp.mutate(
      {
        ...notification
      },
      {
        onError: onError,
        onSuccess: onNotificationCreated
      }
    )
    setDialog(false)
  }

  const initialFormData = useMemo(
    () => ({
      eventId: event.id,
      equipmentTechnicalId:
        event.equipment?.idType === 'technicalId' ? event.equipment?.id : undefined,
      departmentCode: categoryToInforDepartmentCodeMapper(event.category),
      priority: shiftEventToInforPriorityMapper(event.priority)
    }),
    [event]
  )

  return (
    <CreateInforMaintenanceNotificationFormDialog
      open={showDialog}
      onClose={() => {
        setDialog(false)
      }}
      onConfirm={createNotification}
      event={event}
      initialFormData={initialFormData}
    />
  )
}
