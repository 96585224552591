import {MaterialStorage} from '@hconnect/common/types'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useConfig} from '../useConfig'

import {useApi} from './useApi'

const QueryKey = 'material-storage'

export const useMaterialStorage = (
  snapshotDateTime: string,
  options?: UseQueryOptions<MaterialStorage[], AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()

  return useQuery<MaterialStorage[], AxiosError>(
    [QueryKey, snapshotDateTime, plantId],
    async ({signal}) => {
      const materialStorageResponse = await axiosInstance.get<MaterialStorage[]>(
        `/plants/${plantId}/material-storage/history/${snapshotDateTime}`,
        {signal}
      )

      if (!materialStorageResponse.data || materialStorageResponse.data.length === 0) {
        // not configured plant, data should be taken from different endpoint
        const path = `/shifthandover/${plantId}/siloFillLevels`
        const siloFillLevelsResponse = await axiosInstance.get<MaterialStorage[]>(path, {
          params: {
            to: snapshotDateTime
          },
          signal
        })
        return siloFillLevelsResponse?.data || []
      }
      return materialStorageResponse.data
    },
    options
  )
}
