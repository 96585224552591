import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useConfig} from '../useConfig'

import {useApi} from './useApi'

const INSTRUCTIONS_TENANT_ID = 'shiftHandover'

export const useInstructionDelete = (options?: UseMutationOptions<unknown, AxiosError, string>) => {
  const {axiosInstance} = useApi()
  const queryClient = useQueryClient()
  const {plantId} = useConfig()
  return useMutation<unknown, AxiosError, string>(
    async (instructionId: string) => {
      const path = `/instructions/${INSTRUCTIONS_TENANT_ID}/plants/${plantId}/instructions/${instructionId}`
      const response = await axiosInstance.delete(path)
      return response.data
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries()
        await options?.onSuccess?.(data, variables, context)
      }
    }
  )
}
