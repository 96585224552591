import {useMutation, useQueryClient, UseMutationOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {CreateMaintenanceNotification} from '../types/maintenanceNotification.types'

import {useApi} from './api/useApi'
import {QueryKey} from './api/useEvents'
import {useConfig} from './useConfig'

export const useMaintenanceNotification = (
  options?: UseMutationOptions<string, AxiosError, CreateMaintenanceNotification>
) => {
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()
  const queryClient = useQueryClient()

  return useMutation<string, AxiosError, CreateMaintenanceNotification>(
    async ({sourceEventId, workCenterId, plannerGroupId}) => {
      const path = `/shifthandover/${plantId}/maintenance-notification`

      const mutation = await axiosInstance.post<string>(path, {
        sourceEventId,
        workCenterId,
        plannerGroupId
      })
      return mutation.data
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([QueryKey, variables.sourceEventId])
        await options?.onSuccess?.(data, variables, context)
      }
    }
  )
}
