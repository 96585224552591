import {ErrorDialog, ConfirmationDialog} from '@hconnect/common/components/dialogs'
import {UseMutationResult} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {DeleteCommentParameter} from '../../hooks/api/useCommentsAction'
import {Comment} from '../../types/shiftHandover.types'

type CommentDeleteProps = {
  commentId: string
  onClose: () => void
  deleteCommentAction: UseMutationResult<Comment, AxiosError, DeleteCommentParameter, unknown>
}

export const CommentDelete: React.FC<CommentDeleteProps> = ({
  commentId,
  onClose,
  deleteCommentAction
}) => {
  const {t} = useTranslation()

  return (
    <>
      {deleteCommentAction.error ? (
        <ErrorDialog
          httpCode={deleteCommentAction.error.code}
          statusCode={deleteCommentAction.error?.response?.status}
          onClose={() => {
            onClose()
            deleteCommentAction.reset()
          }}
          errorText={deleteCommentAction.error.message}
        />
      ) : (
        <ConfirmationDialog
          onDeleteSingle={() => {
            onClose()
            deleteCommentAction.mutate({commentId})
          }}
          onClose={onClose}
          isSingleLoading={deleteCommentAction.isLoading}
          text={t('shiftEvent.sureToDeleteComment')}
          isSeriesItem={false}
        />
      )}
    </>
  )
}
